import Clipboard from 'stimulus-clipboard';

export default class extends Clipboard {
  static targets = ['button', 'source'];
  static values = {
    successContent: String,
    successClass: String,
    defaultClass: String,
    successDuration: {
      type: Number,
      default: 2000
    }
  };

  connect(e) {
    super.connect(e);
    if (this.hasButtonTarget && this.hasDefaultClassValue) {
      this.buttonTarget.classList.add(this.defaultClassValue);
    }
  }

  copy(e) {
    super.copy(e);
  }

  copied(e) {
    super.copied(e);
    if (this.hasButtonTarget && this.hasSuccessClassValue && this.hasDefaultClassValue) {
      this.buttonTarget.classList.remove(this.defaultClassValue);
      this.buttonTarget.classList.add(this.successClassValue);
      this.timeoutId = setTimeout(() => {
        this.buttonTarget.classList.remove(this.successClassValue);
        this.buttonTarget.classList.add(this.defaultClassValue);
      }, this.successDurationValue);
    }
  }

  disconnect(e) {
    super.disconnect(e);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
