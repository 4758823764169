import { Controller } from '@hotwired/stimulus';
import moment from 'moment';

export default class extends Controller {
    static targets = ['serverTime', 'todayTime', 'in30DaysTime'];

    connect() {
        if (this.hasServerTimeTarget) {
            this.serverTimeTargets.forEach((el) => {
                const serverTime = el.textContent.trim();
                const localTime = moment(serverTime).local();

                // Format the date according to the user's locale
                el.textContent = localTime.format('DD MMMM HH:mm');
                el.classList.remove('invisible');
            });
        }
        if (this.hasTodayTimeTarget) {
            this.todayTimeTarget.textContent = moment().format('MM/DD/YYYY');
        }
        if (this.hasIn30DaysTimeTarget) {
            this.in30DaysTimeTarget.textContent = moment().add(30, 'days').format('MM/DD/YYYY');
        }
    }
}