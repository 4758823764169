import { Controller } from '@hotwired/stimulus';

export default class Accordion extends Controller {
  static targets = [ 'accordion' ];

  manageAccordionState(event) {
    this.notClickedBlocks = this.accordionTargets.filter(
      accordionBlock => accordionBlock.id !== event.currentTarget.parentNode.id
    );
    this.notClickedBlocks.forEach(notClickedBlock => notClickedBlock.classList.remove('accordion__block--open'));
    event.currentTarget.parentNode.classList.toggle('accordion__block--open');
  }
}
