import { Alert } from 'tailwindcss-stimulus-components';

export default class FlashController extends Alert {
  connect() {
    super.connect();

    if (this.data.has('autoClose')) {
      this.autoCloseTid = setTimeout(() => {
        this.close();
        delete this.autoCloseTid;
      }, this.data.get('autoClose'));
    }
  }

  disconnect() {
    super.disconnect();
    if (this.autoCloseTid) {
      clearTimeout(this.autoCloseTid);
    }
  }

  show() {
    this.element.setAttribute(
      'style',
      'transition: 0.15s; transform:translate(0, 0);'
    );
  }

  hide() {
    this.element.setAttribute(
      'style',
      'transition: 0.15s; transform:translate(0, 600px);'
    );
  }
}
