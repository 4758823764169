import { useDirtyFormTracking, useEventListener } from '@stimulus-library/mixins';
import { BaseController } from '@stimulus-library/utilities';

export default class extends BaseController {
  static values = {
    message: String,
    otherLayout: { type: String, default: '/posts/new' }
  };

  _enabled = false;
  _teardowns = [];

  connect() {
    useDirtyFormTracking(this, this.el);
    useEventListener(this, this.el, 'form-dirtied', this._enable);
    useEventListener(this, this.el, 'form-cleaned', this._disable);
  }

  disconnect() {
    window.removeEventListener('beforeunload', this._confirmUnload);
    this._disable();
  }

  get _message() {
    return this.hasMessageValue ? this.messageValue : 'Do you want to leave this page? Changes you made may not be saved';
  }

  _enable() {
    if (this._enabled) {
      return;
    }
    this._enabled = true;
    window.addEventListener('beforeunload', this._confirmUnload);
    const { teardown: submitTeardown } = useEventListener(this, window, ['submit', 'turbo:submit-start'], this._disable);
    const { teardown: popstateTeardown } = useEventListener(this, window, 'popstate', this._confirmNavigation);
    const { teardown: turbolinksTeardown } = useEventListener(this, window, ['turbolinks:before-visit', 'turbo:before-visit'], this._confirmTurboNavigation);
    this._teardowns = [submitTeardown, popstateTeardown, turbolinksTeardown];
  }

  _disable() {
    this._enabled = false;
    this._teardowns.forEach(teardown => teardown());
    this._teardowns = [];
  }

  _confirmUnload(_event) {
    _event.preventDefault();
    _event.returnValue = this._message;
  }

  _confirmNavigation() {
    return false;
  }

  _confirmTurboNavigation(event) {
    // FIXME this block is used to prevent a custom confirm dialog from showing when the user navigates to a different layout
    // with data: { turbo_track: 'reload' } (this.otherLayoutValue holds the path to such a page) — this is causing the page to reload
    // when initially loaded which triggers 'beforeunload' event and as a result: a browser confirmation dialog is shown
    try {
      if (this._isInternalNavigation(event?.detail?.url)) {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
    // end of the block

    if (!confirm(this._message)) {
      event.preventDefault();
    }
  }

  _isInternalNavigation(url) {
    const parsedURL = new URL(url);
    const targetPathname = parsedURL?.pathname || '';

    if (!this.hasOtherLayoutValue) {
      return false;
    }
    try {
      return targetPathname === this.otherLayoutValue;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
