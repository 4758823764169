import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'button', 'preview', 'removeImageBtn', 'removeImage', 'shade'];
  static values = {
    maxSize: { type: Number, default: 5 }, // in MB
    acceptedTypes: { type: Array, default: ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'] },
  };

  selectImage() {
    this.inputTarget.click();
  }

  putShade() {
    if (this.hasShadeTarget) {
      this.shadeTarget.style.opacity = '100';
    }
  }

  removeShade(){
    if (this.hasShadeTarget) {
      this.shadeTarget.style.opacity = '0';
    }
  }

  createObjectURL() {
    if (this.inputTarget.files && this.inputTarget.files[0]) {
      const imageFile = this.inputTarget.files[0];
      if (imageFile) {
        if (!this.isValidImageSize(imageFile)) {
          this.inputTarget.files = new DataTransfer().files;
          const message = `Image size must be less than ${this.maxSizeValue} MB.`;
          this.showFlash(message, 'error');
        } else if (!this.isValidFileType(imageFile)) {
          this.inputTarget.files = new DataTransfer().files;
          const acceptedTypesString = this.acceptedTypesValue.map((type) => type.split('/').pop()).join(', ');
          const message = `We only allow images in these formats to be uploaded: ${acceptedTypesString}.`;
          this.showFlash(message, 'error');
        } else {
          this.previewTargets.forEach(img => img.src = URL.createObjectURL(imageFile));
          this.removeImageBtnTarget.classList.remove('hidden');
          this.removeImageTarget.value = false;
          this.removeShade();
        }
      }
    }
  }

  isValidImageSize(imageFile) {
    return (imageFile.size < this.maxSizeValue * 1000 * 1000);
  }

  isValidFileType(imageFile) {
    return this.acceptedTypesValue.includes(imageFile.type);
  }

  onRemove() {
    this.previewTargets.forEach(img => img.src = '');
    this.removeImageTarget.value = true;
    this.removeImageBtnTarget.classList.add('hidden');
  }

  showFlash(message, flash_type) {
    // FIXME There must be a better way to do this
    const bg = flash_type == 'alert' || flash_type == 'error' ? 'bg-danger' : 'bg-deep_blue';
    const flashDiv = document.createElement('div');
    flashDiv.setAttribute('data-controller', 'flash');
    flashDiv.setAttribute('data-flash-auto-close', '60000');
    flashDiv.classList.add(bg, 'fixed', 'bottom-0', 'tablet:left-1/2', 'tablet:-ml-[214px]', 'w-full', 'tablet:w-[428px]', 'p-4', 'tablet:p-6', 'text-white',  'rounded-t-2xl', 'tablet:rounded-t-[32px]', 'shadow-lg', 'pointer-events-auto', 'z-[9999]');
    flashDiv.style.transform = 'translate(0, 600px)';

    const messageParagraph = document.createElement('p');
    messageParagraph.classList.add('pr-4');
    messageParagraph.innerText = message;

    const closeButton = document.createElement('button');
    closeButton.setAttribute('data-action', 'flash#close');
    closeButton.classList.add('absolute', 'right-0', 'inline-flex', 'p-4', 'text-white', 'top-0', 'md:top-2', 'focus:outline-none');

    const closeIcon = document.createElement('span');
    // eslint-disable-next-line quotes
    closeIcon.innerHTML = "<svg class='w-4 h-4' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 18L18 6M6 6l12 12'></path></svg";

    closeButton.appendChild(closeIcon);
    flashDiv.appendChild(messageParagraph);
    messageParagraph.appendChild(closeButton);

    const flashContainer = document.getElementById('flash');
    flashContainer.appendChild(flashDiv);
  }
}
