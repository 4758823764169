import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { delay: { type: Number, default: 5 } };

  // FIXME "comment-highlighted" class should be fully controlled here: adding and removing
  connect(){
    this.removeHighlight();
  }

  disconnect() {
    if (this.removeHighlightTid) {
      clearTimeout(this.removeHighlightTid);
    }
  }

  removeHighlight() {
    this.removeHighlightTid = setTimeout(() => {
      this.element.classList.remove('comment-highlighted');
      delete this.removeHighlightTid;
    }, this.delayValue * 1000);
  }
}
