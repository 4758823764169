import debounce from 'lodash.debounce';
import Autosave from 'stimulus-rails-autosave';

export default class extends Autosave {
  static targets = ['input', 'clearButton' ];

  static values = {
    delay: { type: Number, default: 500 }
  };

  initialize() {
    super.initialize();
    this.autosubmit = this.autosubmit.bind(this);
    this.submit = this.submit.bind(this);
  }

  connect() {
    if (this.delayValue > 0) {
      this.autosubmit = debounce(this.autosubmit, this.delayValue);
    }
    super.connect();
    this._toggleClearButton();
    if (this.hasInputTarget) {
      this.inputTarget.addEventListener('input', this._toggleClearButton.bind(this));
    }
  }

  disconnect() {
    super.disconnect();
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener('input', this._toggleClearButton.bind(this));
    }
  }

  clearInput(e) {
    if (this.hasInputTarget) {
      if (this.inputTarget?.value?.length > 0) {
        this.inputTarget.value = '';
      }
      this.inputTarget.focus();
      if (!this.inputTarget.hasAttribute('required')) {
        this.submit(e);
      }
    }
    this._toggleClearButton();
  }

  autosubmit(e) {
    super.save(e);
  }

  submit(e) {
    if (this.hasInputTarget && this.inputTarget.value) {
      const trimmedValue = this._trimmedValue(this.inputTarget.value);
      this.inputTarget.value = trimmedValue;
      if (this.inputTarget.hasAttribute('required') || this.inputTarget.hasAttribute('minlength')) {
        const minLength = this.inputTarget.getAttribute('minlength') || 1;
        if (trimmedValue.length < minLength) {
          e.preventDefault();
          return;
        }
      }
    }
    super.save(e);
  }

  _toggleClearButton() {
    if (this.hasInputTarget && this.hasClearButtonTarget) {
      const trimmedValue = this.inputTarget.value.trim() || '';
      if (trimmedValue.length === 0) {
        this._hideClearButton();
      } else {
        this._showClearButton();
      }
    }
  }

  _trimmedValue(value){
    return value.trim() || '';
  }

  _showClearButton() {
    if (this.hasInputTarget && this.hasClearButtonTarget) {
      if (this.clearButtonTarget.classList.contains('opacity-0')) {
        this.clearButtonTarget.classList.remove('opacity-0');
      }

      if (this.clearButtonTarget.classList.contains('pointer-events-none')) {
        this.clearButtonTarget.classList.remove('pointer-events-none');
      }
    }
  }

  _hideClearButton() {
    if (this.hasInputTarget && this.hasClearButtonTarget) {
      if (!this.clearButtonTarget.classList.contains('opacity-0')) {
        this.clearButtonTarget.classList.add('opacity-0');
      }

      if (!this.clearButtonTarget.classList.contains('pointer-events-none')) {
        this.clearButtonTarget.classList.add('pointer-events-none');
      }
    }
  }
}