import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'hint'];

  static values = {
    current: String
  };

  currentValueChanged(value) {
    if (value === '') {
      return this.hideHint();
    }

    return this.showHint();
  }

  setGender(e) {
    const { value } = e.target;
    this.currentValue = value;
  }

  showHint() {
    this.hintTarget.classList.remove('opacity-0');
  }

  hideHint(){
    this.hintTarget.classList.add('opacity-0');
  }
}
