import { Modal } from 'tailwindcss-stimulus-components';

export default class ModalController extends Modal {
  static targets = ['turboFrame', 'autofocus', 'errorMessage', 'field', 'closeIcon'];

  clearModalState() {
    if (this.hasFieldTarget) {
      this.fieldTargets.forEach((field) => {
        field.value = '';
        field.classList.remove('form__input-errored');
      });
    }

    if (this.hasErrorMessageTarget) {
      this.errorMessageTargets.forEach((errorMessage) => {
        errorMessage.innerText = '';
        errorMessage.classList.remove('form__input-errored');
      });
    }
  }

  close() {
    this.clearModalState();

    super.close();

    if (this.hasTurboFrameTarget) {
      this.turboFrameTarget.src = null;
      this.turboFrameTarget.innerHTML = '';
    }
  }

  open(e) {
    super.open(e);

    if (this.hasAutofocusTarget) {
      const len = this.autofocusTarget.value.length;
      this.autofocusTarget.focus();
      this.autofocusTarget.setSelectionRange(len, len);
    } else {
      if (this.hasCloseIconTarget) {
        this.closeIconTarget.focus();
      }
    }
  }

  lockScroll() {
    // super.lockScroll();
    // FIX problem with restore-scroll-value="false"
    // Save the scroll position before we hide the scrollbar
    if (this.restoreScrollValue) {
      this.saveScrollPosition();
      // Add negative top position in order for body to stay in place
      document.body.style.top = `-${this.scrollPosition}px`;
    }

    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden');
  }

  escClose(event) {
    if (event.key === 'Escape') this.close();
  }
}
