import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  closeModal() {
    window.dispatchEvent(new CustomEvent('close-modal'));
  }

  changeUrl(event){
    const url = event.target?.dataset?.url;
    if (url && typeof url === 'string') {
      history.pushState({}, '', url);
    }
  }
}
