import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];

  static values = {
    moreText: String,
    lessText: String
  };

  connect () {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'line-clamp-3';
    this.open = false;
  }

  toggle (event) {
    this.open === false ? this.show(event) : this.hide(event);
  }

  show (event) {
    this.open = true;

    const target = event.target;
    target.innerHTML = this.lessTextValue;
    this.contentTarget.classList.remove(this.class);
  }

  hide (event) {
    this.open = false;

    const target = event.target;
    target.innerHTML = this.moreTextValue;
    this.contentTarget.classList.add(this.class);
  }
}
