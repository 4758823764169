import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container', 'button'];
  static values = {
    offset: Number,
  };

  connect(){
    this.toggleButtonVisibility();
    this.toggleButtonVisibilityHandler = () => this.toggleButtonVisibility();
    window.addEventListener('scroll', this.toggleButtonVisibilityHandler);
  }

  disconnect() {
    window.removeEventListener('scroll', this.toggleButtonVisibilityHandler);
  }

  scroll(e){
    e.preventDefault();
    const offset = this.offsetValue || 0;

    if (this.containerTarget) {
      const elementPosition = this.containerTarget.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
  }

  // Private
  toggleButtonVisibility(){
    const containerRect = this.containerTarget.getBoundingClientRect();
    const show = -containerRect.top > window.innerHeight;
    this.buttonTarget.style.visibility = show ? 'visible' : 'hidden';
    this.buttonTarget.style.opacity = show ? '1' : '0';
  }
}
