/* global google */
import { Controller } from '@hotwired/stimulus';
import Tagify from '@yaireo/tagify';
import debounce from 'lodash.debounce';

export default class extends Controller {
  static targets = [ 'geoInput', 'geoBlock' ];

  static values = {
    maxTags: { type: Number, default: 3 },
    maxChars: { type: Number, default: 50 },
    delay: { type: Number, default: 500 }
  };

  initialize() {
    this.onInput = this.onInput.bind(this);
    if (this.delayValue > 0) {
      this.onInput = debounce(this.onInput, this.delayValue);
    }
    this.handleResponse = this.handleResponse.bind(this);
    this.initializeGooglePlaces = this.initializeGooglePlaces.bind(this);

    // hide the geo block until geoservice is ready
    if (this.hasGeoBlockTarget) {
      this.geoBlockTarget.classList.add('hidden');
    }

    // wait for the google places library to load
    document.addEventListener('googlePlacesLoaded', this.initializeGooglePlaces);

    // check if the google places library is already loaded before the controller is initialized
    if (window?.googlePlacesLoaded) {
      this.initializeGooglePlaces();
    }
  }

  initializeGooglePlaces() {
    if (!this.service) {
      this.service = new google.maps.places.AutocompleteService();
    }

    if (!this.sessionToken) {
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    }

    if (this.service && this.sessionToken && this.hasGeoBlockTarget) {
      this.geoBlockTarget.classList.remove('hidden');
    }
  }

  connect() {
    this.tagify = this.tagifyInstance;
    let controller;
    this.controller = controller;
    this.tagify.on('input', this.onInput);
  }

  disconnect() {
    this.tagify.destroy();
    document.removeEventListener('googlePlacesLoaded', this.initializeGooglePlaces);
  }

  handleResponse(predictions, status) {
    if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
      this.tagify.loading(false);
    }
  }

  async onInput(event) {
    const { value } = event.detail;
    this.controller && this.controller.abort();
    this.controller = new AbortController();

    this.tagify.loading(true).dropdown.hide();
    const self = this;

    if (!this.service || !this.sessionToken) {
      this.initializeGooglePlaces();
      return;
    }

    if (this.service && this.sessionToken && this.service?.getPlacePredictions) {
      this.service.getPlacePredictions({ input: value, language: 'en', types: ['(regions)'], region: 'us', componentRestrictions: { country: 'us' }, sessionToken: this.sessionToken }, this.handleResponse)
        .then((res) => {
          const predictionsArray = res?.predictions.map(prediction => prediction.description.replace(',', ''));
          const mergedPredictionsArray = [...new Set([...self.tagify.whitelist, ...predictionsArray])];
          self.tagify.whitelist = mergedPredictionsArray;
          self.tagify.loading(false).dropdown.show(value);
        })
        .catch(() => self.tagify.loading(false).dropdown.hide());
    }
  }

  get tagifyInstance() {
    return new Tagify(this.geoInputTarget, {
      dropdown : {
        enabled: 1,
        maxItems: 5,
        minItems: 1,
        caseSensitive: false,
        closeOnSelect: false,
      },
      enforceWhitelist: true,
      whitelist: (this.hasGeoInputTarget && this.geoInputTarget?.value?.length > 0 ? this.geoInputTarget?.value?.split(',') || [] : []),
      pattern: /^.{1,55}$/,
      maxTags: this.maxTagsValue,
      originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.value)
    });
  }
}
