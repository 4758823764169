import Dropdown from 'stimulus-dropdown';

export default class extends Dropdown {
  static values = { disableScroll: { type: Boolean, default: false } };

  connect() {
    super.connect();
    if (this.disableScrollValue) {
      this.bodyElement = document.querySelector('body');
    }
  }

  disconnect() {
    if (this.disableScrollValue && this.bodyElement) {
      this.enablePageScroll();
    }
  }

  // FIXME maybe better move enable/disable scroll logic to the toggle method
  toggle(event) {
    super.toggle(event);
  }

  hide(event) {
    if (this.disableScrollValue && this.bodyElement) {
      this.enablePageScroll();
    }
    super.hide(event);
  }

  disablePageScroll() {
    this.bodyElement.style.overflow = 'hidden';
  }

  enablePageScroll() {
    this.bodyElement.style.overflow = 'auto';
  }
}
