import { Controller } from '@hotwired/stimulus';

export default class RatingController extends Controller {
    static targets = ['star'];
    currentRating = 0;

    // Set the rating based on user click
    setRating(event) {
        const selectedRating = this.getRatingFromEvent(event);
        this.updateStarsDisplay(selectedRating);
        this.currentRating = selectedRating;
    }

    // Update display on mouse over
    mouseOver(event) {
        this.updateStarsDisplay(this.getRatingFromEvent(event));
    }

    // Reset to current rating on mouse out
    mouseOut() {
        this.updateStarsDisplay(this.currentRating);
    }

    // Helper method to get rating value from the event
    getRatingFromEvent(event) {
        return Number(event.currentTarget.getAttribute('data-rating-value'));
    }

    // Update the star icons display based on the rating
    updateStarsDisplay(rating) {
        this.starTargets.forEach((star, index) => {
            const starIcon = star.querySelector('.star-icon');
            starIcon.classList.toggle('text-yellow_active', index < rating);
        });
    }
}
