import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['banner'];
  static values = {
    name: String,
    state: { type: String, default: 'closed' },
    days: { type: Number, default: 30 },
    errorId: String,
    paymentId: String,
  };

  connect() {
    if (this._shouldDeleteCookie()) {
      this._deleteCookie(this.nameValue);
    }
    this._isBannerClosedByUser() ? this._hide() : this._show();
  }

  close() {
    this._hide();
    this._setCookie();
  }

  _hide() {
    if (this.hasBannerTarget) {
      if (!this.bannerTarget.classList.contains('hidden')) {
        this.bannerTarget.classList.add('hidden');
      }
    }
  }

  _show() {
    if (this.hasBannerTarget) {
      if (this.bannerTarget.classList.contains('hidden')) {
        this.bannerTarget.classList.remove('hidden');
      }
    }
  }

  _shouldDeleteCookie() {
    return this.hasPaymentIdValue && this.paymentIdValue !== '' && this.hasNameValue;
  }

  _isBannerClosedByUser() {
    if (this.hasNameValue && this.hasErrorIdValue && this.errorIdValue !== '') {
      return this._getCookie(this.nameValue) === `${this.stateValue}_${this.errorIdValue}`;
    } else if (this.hasNameValue) {
      return this._getCookie(this.nameValue) === this.stateValue;
    }
    return false;
  }

  _getCookie(name) {
    const cookies = document.cookie.split('; ');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].split('=');
      if (cookie[0] === name) {
        return decodeURIComponent(cookie[1]);
      }
    }
    return null;
  }

  _setCookie() {
    if (this.hasNameValue) {
      if (this.hasErrorIdValue && this.errorIdValue !== '') {
        console.log('setting error id cookie');
        this._setIdCookie(this.nameValue, this.stateValue, this.errorIdValue);
      } else {
        console.log('setting time cookie');
        this._setTimeCookie(this.nameValue, this.stateValue, this.daysValue);
      }
    }
  }

  _setIdCookie(name, state, id) {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    document.cookie = `${name}=${state}_${id}; expires=${expirationDate.toUTCString()}; path=/`;
  }

  _setTimeCookie(name, state, days) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    document.cookie = `${name}=${state}; expires=${expirationDate.toUTCString()}; path=/`;
  }

  _deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }
}
