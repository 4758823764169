import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'button'];
  static values = {
    maxHeight: { type: Number, default: 14 * 1.4 * 3 },
    foldClass: { type: String, default: 'notification__content-folded' }
  };

  isFolded;

  connect () {
    if (this.hasContentTarget && this.hasButtonTarget) {
      const offsetHeight = this.contentTarget?.offsetHeight || 0;
      if (offsetHeight > this.maxHeightValue) {
        this.buttonTarget.classList.remove('hidden');
        this.fold();
      }
    }
  }

  toggle() {
    this.isFolded ? this.unfold() : this.fold();
  }

  fold() {
    this.buttonTarget.innerHTML = 'Show more';
    this.contentTarget.classList.add(this.foldClassValue);
    this.isFolded = true;
  }

  unfold() {
    this.buttonTarget.innerHTML = 'Show less';
    this.contentTarget.classList.remove(this.foldClassValue);
    this.isFolded = false;
  }
}
