import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static values = {
    mobile: { type: Boolean, default: false }
  };

  connect() {
    if (this.hasMobileValue && this.mobileValue) {
      this.mountSwiperMobile();
    } else {
      this.mountSwiperDesktop();
    }
  }

  mountSwiperMobile() {
    this.swiper = new Swiper('.swiperMobile', {
      direction: 'horizontal',
      slidesPerView: 1,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
      },
      a11y: {
        prevSlideMessage: 'Previous picture of the product or service',
        nextSlideMessage: 'Next picture of the product or service',
      },
    });
  }

  mountSwiperDesktop() {
    this.swiperVertical = new Swiper('.swiperVertical', {
      direction: 'vertical',
      spaceBetween: 4,
      slidesPerView: 3.3,
      mousewheel: true,
      keyboard: {
        enabled: true,
      },
      a11y: {
        prevSlideMessage: 'Previous picture of the product or service',
        nextSlideMessage: 'Next picture of the product or service',
      },
    });

    this.swiperBigView = new Swiper('.swiperBigView', {
      spaceBetween: 4,
      thumbs: {
        swiper: this.swiperVertical,
      },
      keyboard: {
        enabled: true,
      },
      a11y: {
        prevSlideMessage: 'Previous picture of the product or service',
        nextSlideMessage: 'Next picture of the product or service',
      },
    });
  }
}
