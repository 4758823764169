import CharacterCounter from 'stimulus-character-counter';
import Dropdown from 'stimulus-dropdown';
import { FocusStealController, FormDirtyConfirmNavigationController } from 'stimulus-library';
import PasswordVisibility from 'stimulus-password-visibility';
import Autosave from 'stimulus-rails-autosave';
import TextareaAutogrow from 'stimulus-textarea-autogrow';
import { registerControllers } from 'stimulus-vite-helpers';
import {
  Toggle,
  Modal
} from 'tailwindcss-stimulus-components';

import { stimulus } from '~/init';

const controllers = import.meta.glob('./**/*_controller.js', { eager: true });

stimulus.register('autosave', Autosave);
stimulus.register('character-counter', CharacterCounter);
stimulus.register('confirm-navigation', FormDirtyConfirmNavigationController);
stimulus.register('dropdown', Dropdown);
stimulus.register('focus-steal', FocusStealController);
stimulus.register('localModal', Modal);
stimulus.register('password-visibility', PasswordVisibility);
stimulus.register('textarea-autogrow', TextareaAutogrow);
stimulus.register('toggle', Toggle);

registerControllers(stimulus, controllers);
