import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    open: { type: Boolean, default: false },
    changeText: { type: Boolean, default: true },
    moreText: { type: String, default: 'Show more' },
    lessText: { type: String, default: 'Show less' },
  };
  static targets = ['item', 'button'];
  static classes = [ 'hidden' ];

  connect () {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden';
  }

  toggle(e) {
    this.openValue === false ? this.show(e) : this.hide(e);
  }

  show (event) {
    this.openValue = true;

    this.itemTargets.forEach(item => {
      item.classList.remove(this.class);
    });

    if (this.changeTextValue) {
      if (this.buttonTarget) {
        this.buttonTarget.innerHTML = this.lessTextValue;
        this.buttonTarget.classList.remove(this.class);
      } else {
        const target = event.target;
        target.innerHTML = this.lessTextValue;
      }
    }
  }

  hide (event) {
    this.openValue = false;

    this.itemTargets.forEach(item => {
      item.classList.add(this.class);
    });

    if (this.changeTextValue) {
      if (this.buttonTarget) {
        this.buttonTarget.innerHTML = this.moreTextValue;
        this.buttonTarget.classList.remove(this.class);
      } else {
        const target = event.target;
        target.innerHTML = this.moreTextValue;
      }
    }
  }
}
