import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';

export default class TippyController extends Controller {
  static values = { content: String };
  tippyInstance = null;

  initialize() {
    this.tippyInstance = tippy(this.element, {
      theme: 'custom',
      content: this.contentValue,
      allowHTML: true,
      hideOnClick: true,
      trigger: 'mouseenter',
      delay: [300, 200]
    });
  }

  connect() {
    window.addEventListener('scroll', this.hideTippy.bind(this));
  }

  hideTippy() {
    if (this.tippyInstance) {
      this.tippyInstance.hide();
    }
  }

  disconnect() {
    window.removeEventListener('scroll', this.hideTippy.bind(this));
  }
}
