import { Controller } from '@hotwired/stimulus';
import Tagify from '@yaireo/tagify';

export default class extends Controller {
  static values = {
    maxTags: { type: Number, default: 10 },
    maxChars: { type: Number, default: 200 }
  };

  initialize() {
    this.onInput = this.onInput.bind(this);
    this.onKeydown = this.onKeydown.bind(this);
  }

  connect() {
    this.tagify = this.tagifyInstance;

    // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
    let controller;
    this.controller = controller;

    this.tagify.on('input', this.onInput);
    this.tagify.DOM.input.addEventListener('keydown', this.onKeydown);
  }

  onKeydown(e) {
    if (e.key === '#') {
      e.preventDefault();
    }
  }

  onInput(event) {
    const { value } = event.detail;
    this.tagify.whitelist = null;

    this.controller && this.controller.abort();
    this.controller = new AbortController();

    if(this.element.dataset.tagsPath) {
      this.tagify.loading(true).dropdown.hide();

      const self = this;

      fetch(`${self.element.dataset.tagsPath}?query=${encodeURIComponent(value)}`, { signal: self.controller.signal })
        .then((RES) => RES.json())
        .then((whitelist) => {
          self.tagify.whitelist = whitelist;
          self.tagify.loading(false).dropdown.show(value);
        })
        .catch(() => self.tagify.dropdown.hide());
    }
  }

  disconnect() {
    this.tagify.destroy();
  }

  get tagifyInstance() {
    return new Tagify(this.element, {
      dropdown: {
        enabled: 1,
        maxItems: 10,
        minItems: 1,
        fuzzySearch: false,
        caseSensitive: false,
        closeOnSelect: false,
      },
      pattern: /^.{1,55}$/,
      maxTags: this.maxTagsValue,
      originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.value)
    });
  }
}
