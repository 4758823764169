import { Controller } from '@hotwired/stimulus';
import { loadStripe } from '@stripe/stripe-js';

export default class extends Controller {
  static values = {
    secret: String,
    publicKey: String
  };

  static targets = ['paymentElement', 'addressElement'];

  appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0A132D',
      colorText: '#0A132D',
      colorTextSecondary: '#6E7581',
      colorDanger: '#DA1600',
      colorSuccess: '#75D377',
      fontFamily: 'Lato, ui-sans-serif, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif',
      fontSizeSm: '14px',
      fontLineHeight: '1.4',
      borderRadius: '6px',
      focusBoxShadow: 'none',
      spacingGridRow: '16px',
      colorTextPlaceholder: '#6E7581',
      iconCardCvcColor: '#fff',
      spacingUnit: '3.3px',
    },
    rules: {
      '.Label': {
        fontSize: '16px',
        fontWeight: 400,
        marginBottom: '4px',
      },
      '.Input': {
        boxShadow: 'none',
        border: '1px solid #AEB5C1',
      },
      '.Input:focus': {
        border: '1px solid #0A132D',
      },
      '.TermsText': {
        fontSize: '14px',
      },
    }
  };

  async connect() {
    if(this.hasPaymentElementTarget && this.hasAddressElementTarget) {
      const stripe = await loadStripe(this.publicKeyValue);

      const elements = stripe.elements({
        clientSecret: this.secretValue,
        appearance: this.appearance,
        loader: 'always',
        fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Urbanist:wght@600;700&family=Lato:wght@300;400;600;700&display=swap' }]
      });

      const addressElement = elements.create('address', { mode: 'billing' });
      const paymentElement = elements.create('payment', { business: { name: 'saymore' } });

      paymentElement.mount(this.paymentElementTarget);
      addressElement.mount(this.addressElementTarget);

      this.element.addEventListener('turbo:before-fetch-request', async (e) => {
        e.preventDefault();

        const result = await stripe.confirmSetup({
          elements: elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: location.href
          }
        });

        if (result.error) {
          if (result.error.type === 'card_error') {
            document.getElementById('stripe-error-message').textContent = result.error.message;
          } else {
            document.getElementById('stripe-error-message').textContent = '';
          }
        }

        const paymentMethodId = result.setupIntent.payment_method;
        e.detail.fetchOptions.body.set('payment_method_id', paymentMethodId);
        e.detail.resume();
      });
    }
  }
}
