import { FetchRequest } from '@rails/request.js';
import Tagify from '@yaireo/tagify';

import Search from './search_controller';

export default class extends Search {
  static targets = ['input', 'geoInput'];

  static values = {
    geotagsUrl: String,
  };

  initialize() {
    super.initialize();
    this.onGeoSelect = this.onGeoSelect.bind(this);
  }

  connect() {
    super.connect();
    this.tagify = this.tagifyInstance;
    let controller;
    this.controller = controller;
    this.tagify.on('input', this.onGeoSelect);
    this.tagify.on('add', this.autosubmit);
    this.tagify.on('remove', this.autosubmit);
  }

  disconnect() {
    super.disconnect();
    this.tagify.destroy();
  }

  async onGeoSelect(event) {
    const { value } = event.detail;
    this.controller && this.controller.abort();
    this.controller = new AbortController();

    this.tagify.loading(true);

    const self = this;
    const request = new FetchRequest('get', `${this.geotagsUrlValue}?q=${value}`);
    const response = await request.perform();
    if (response.ok) {
      const predictions = await response.json;
      const mergedPredictionsArray = [...new Set([...self.tagify.whitelist, ...predictions])];
      self.tagify.whitelist = mergedPredictionsArray;
      self.tagify.loading(false).dropdown.show(value);
    } else {
      self.tagify.loading(false).dropdown.hide();
    }
  }

  get tagifyInstance() {
    return new Tagify(this.geoInputTarget, {
      dropdown : {
        enabled: 0,
        maxItems: 5,
      },
      enforceWhitelist: true,
      mode : 'select',
      whitelist: (this.hasGeoInputTarget && this.geoInputTarget?.value ? this.geoInputTarget?.value?.split(',') || [] : []),
      originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.value),
      templates: {
        dropdownItemNoMatch: function(data) {
          return `<div class='p-3' value="noMatch" tabindex="0" role="option">No such geotag found: <strong>${data.value}</strong></div>`;
        }
      }
    });
  }
}