import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.onScroll = this.onScroll.bind(this);
    document.addEventListener('scroll', this.onScroll);
  }

  disconnect() {
    document.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    const halfwayPoint = document.documentElement.scrollHeight / 2;
    const currentScroll = window.scrollY + window.innerHeight;

    if (currentScroll >= halfwayPoint) {
      Turbo.visit("/login", { frame: "modal" });
      document.removeEventListener('scroll', this.onScroll);
    }
  }
}
