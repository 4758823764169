import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menuButton', 'menu'];
  static values = {
    menuClass: { type: String, default: 'menu_button-change' },
    hideClass: { type: String, default: 'hidden' },
    menuOpen: { type: Boolean, default: false },
  };

  connect() {
    this.bodyElement = document.querySelector('body');
  }

  disconnect() {
    this._enablePageScroll();
  }

  showMenu() {
    this.hasMenuTarget && this.menuTarget?.classList.remove(this.hideClassValue);
    this.menuOpenValue = true;
    this.hasMenuButtonTarget && this.menuButtonTarget?.classList.add(this.menuClassValue);
    this._disablePageScroll();
  }

  hideMenu() {
    this.hasMenuTarget && this.menuTarget?.classList.add(this.hideClassValue);
    this.menuOpenValue = false;
    this.hasMenuButtonTarget && this.menuButtonTarget?.classList.remove(this.menuClassValue);
    this._enablePageScroll();
  }

  toggleMenuState() {
    this.menuOpenValue === false ? this.showMenu() : this.hideMenu();
  }

  _disablePageScroll() {
    this.bodyElement.style.overflow = 'hidden';
  }

  _enablePageScroll() {
    this.bodyElement.style.overflow = 'auto';
  }
}
