import { Modal } from 'tailwindcss-stimulus-components';

export default class extends Modal {
  static targets = ['turboFrame', 'autofocus'];

  close() {
    super.close();

    if (this.hasTurboFrameTarget) {
      this.turboFrameTarget.src = null;
      this.turboFrameTarget.innerHTML = '';
    }
  }

  open(e) {
    super.open(e);

    if (this.hasAutofocusTarget) {
      const len = this.autofocusTarget.value.length;
      this.autofocusTarget.focus();
      this.autofocusTarget.setSelectionRange(len, len);
    }
  }

  escClose(event) {
    if (event.key === 'Escape') this.close();
  }
}
