import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['parent', 'children'];

  connect() {
    this.enableChildren();
  }

  enableChildren() {
    this.childrenTargets.forEach((child) => {
      child.disabled = !this.parentTarget.checked;
      if(!this.parentTarget.checked) {
        child.checked = false;
      }
    });
  }
}
