import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        url: String,
        handled: Boolean
    };

    connect() {
        const url = this.urlValue;
        if (!this.handledValue && url) {
            Turbo.visit(url, { action: 'replace' } );
            this.handledValue = true;
        }
    }
}
