import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { delay: { type: Number, default: 100 } };
  static targets = ['target', 'template', 'lines', 'number'];

  connect() {
    this.setOrdinalNumbers();
  }

  disconnect() {
    if (this.removeRuleTid) {
      clearTimeout(this.removeRuleTid);
    }
  }

  add(e) {
    e.preventDefault();
    this.targetTarget.insertAdjacentHTML('beforeend', this.templateTarget.innerHTML);
    this.setOrdinalNumbers();
  }

  remove(el) {
    el.parentNode.remove();
    this.setOrdinalNumbers();
  }

  checkRule(e) {
    const target = e.target;

    if (this.linesTargets.length > 1 && target.value == '') {
      this.removeRuleTid = setTimeout(() => {
        this.remove(target);
        delete this.removeRuleTid;
      }, this.delayValue);
    }
  }

  setOrdinalNumbers() {
    this.numberTargets.forEach((numberItem, index) => {
      numberItem.innerHTML = `${index + 1}.`;
    });
  }
}
