import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        this.frameMissingHandler = (event) => {
            const { detail: { response, visit } } = event;
            event.preventDefault();
            visit(response.url);
        };

        this.element.addEventListener('turbo:frame-missing', this.frameMissingHandler);
    }

    disconnect() {
        this.element.removeEventListener('turbo:frame-missing', this.frameMissingHandler);
    }
}
