import { Controller } from '@hotwired/stimulus';
import 'lite-youtube-embed';

export default class extends Controller {
    connect() {
        this.initializeAllVideoEmbeds();
    }

    initializeAllVideoEmbeds() {
        this.element.querySelectorAll('o-embed[url]').forEach(embedElement => {
            this.initializeVideoEmbed(embedElement);
        });
    }

    initializeVideoEmbed(embedElement) {
        const url = embedElement.getAttribute('url');
        const youtubeMatch = url.match('^(?:(?:https?):)?(?:\\/\\/)?[^/]*(?:youtube(?:-nocookie)?.com|youtu.be)(?:.*[=/]([-\\w]{11})(?:\\?|=|&|$))?(?:.*&t=(\\d+))?');

        if (!youtubeMatch || !youtubeMatch[1]) return;

        const videoId = youtubeMatch[1];
        const startTime = youtubeMatch[2];
        const liteYoutubeEmbed = this.createLiteYoutubeEmbed(videoId, startTime);
        embedElement.replaceWith(liteYoutubeEmbed);
    }

    createLiteYoutubeEmbed(videoId, startTime) {
        const tag = document.createElement('lite-youtube');
        tag.setAttribute('videoid', videoId);
        if (startTime) {
            tag.setAttribute('params', `start=${startTime}`);
        }
        return tag;
    }
}
