import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element?.focus();
    this.moveCursorToEnd();
  }

  moveCursorToEnd() {
    const input = this.element;
    const valueLength = input?.value?.length || 0;
    input?.setSelectionRange(valueLength, valueLength);
  }
}
