import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js';

export default class extends Controller {
  static values = {
    searchEnabled: Boolean,
    remoteSearchUrl: String,
    noChoicesText: String,
    noResultsText: String,
    hideRemoveItemButton: Boolean,
    renderChoiceLimit: Number,
    autoClose: Boolean
  };

  connect() {
    if (this.element.dataset.choice !== 'active') {
      const choices = new Choices(this.element, {
        allowHTML: true,
        silent: true,
        searchEnabled: this.searchEnabledValue,
        searchChoices: !this.remoteSearchUrlValue,
        noResultsText: this.noResultsTextValue || 'No results found',
        noChoicesText: this.noChoicesTextValue || 'No choices to choose from',
        searchFloor: 1,
        searchResultLimit: 10,
        shouldSort: false,
        maxItemCount: -1,
        renderChoiceLimit: this.renderChoiceLimitValue || -1,
        removeItems: true,
        delimiter: ',',
        removeItemButton: !this.hideRemoveItemButtonValue,
        duplicateItemsAllowed: false,
        addItems: true,
        fuseOptions: { threshold: 0.2 }
      });

      if(this.autoCloseValue) {
        choices.passedElement.element.addEventListener(
          'addItem',
          function() {
            choices.hideDropdown();
          },
          false
        );
      }

      if (this.remoteSearchUrlValue) {
        const lookupDelay = 300;
        let lookupTimeout = null;

        this.element.addEventListener('search', (event) => {
          clearTimeout(lookupTimeout);

          lookupTimeout = setTimeout(() => {
            choices.clearChoices();
            choices.setChoices(async () => {
              const response = await fetch(
                this.remoteSearchUrlValue + event.detail.value
              );
              const data = await response.json();
              return data;
            }).then(() => {
              event.target.parentNode.querySelector('input').focus();
            });
          }, lookupDelay);
        });
      }
    }
  }
}
