import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['body', 'panel', 'last', 'navbar', 'ghost', 'card', 'triggerButton'];

  triggerButtonTop;
  cardsAnimationAdded = false;
  topBreakpoint;
  bottomBreakpoint;

  connect() {
    this.handleScroll = this.handleScroll.bind(this);
    this.calculateWindowBreakpoints = this.calculateWindowBreakpoints.bind(this);
    this.windowOnLoadHandler = this.windowOnLoadHandler.bind(this);

    if (this.hasBodyTarget && this.hasPanelTarget) {
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll);
    }
    if (this.hasTriggerButtonTarget) {
      this.triggerButtonTop = this.triggerButtonTarget.offsetTop;
    }
    document.documentElement.style.setProperty('--animate-delay', '0.1s');
    window.onload = this.windowOnLoadHandler;
    this.calculateWindowBreakpoints();
    setTimeout(() => {
      this.removeClasses();
    }, 2000);
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  windowOnLoadHandler() {
    this.calculateWindowBreakpoints();
  }

  calculateWindowBreakpoints() {
    this.topBreakpoint = window.innerHeight * 0.3;
    this.bottomBreakpoint = window.innerHeight * 0.8;
  }

  removeClasses() {
    this.ghostTargets.forEach((ghost) => {
      ghost.classList.remove('animate__animated');
    });
  }

  handleScroll() {
    // Change the theme
    const scroll = window.scrollY + (window.innerHeight);
    const { bodyTarget, navbarTarget } = this;
    this.panelTargets.forEach(panel => {
      if (panel.offsetTop <= scroll && panel.offsetTop + panel.offsetHeight > scroll) {
        const themeClass = `theme-${panel.dataset.theme}`;

        this.changeColor(navbarTarget, themeClass);
        this.changeColor(bodyTarget, themeClass);
      }
    });

    if (this.hasTriggerButtonTarget && !this.cardsAnimationAdded) {
      if (window.scrollY + window.innerHeight >= this.triggerButtonTop) {
        this.cardsAnimationAdded = true;
        this.cardTargets.forEach((card, index) => {
          const delayValue = index + 1;
          card.classList.add('animate__animated', 'animate__fadeInUp', `animate__delay-${delayValue}s`);
        });
      }
    }

    this.ghostTargets.forEach((ghost) => {
      const ghostRect = ghost.getBoundingClientRect();
      const ghostDistanceToTop = ghostRect.top;
      const ghostDistanceToBottom = window.innerHeight - ghostRect.top;

      if (ghostDistanceToTop < 0 || ghostDistanceToTop > window.innerHeight) {
        ghost.style.opacity = 0;
      } else {
        if (ghostDistanceToTop > this.bottomBreakpoint) {
          ghost.style.opacity = ghostDistanceToBottom / (window.innerHeight - this.bottomBreakpoint);
        } else if (ghostDistanceToTop <  this.topBreakpoint) {
          ghost.style.opacity = ghostDistanceToTop / this.topBreakpoint;
        } else {
          ghost.style.opacity = 1;
        }
      }
    });
  }

  changeColor(target, themeClass) {
    // Remove classes starting with "theme-"
    target.classList.remove(...Array.from(target.classList).filter(className => className.startsWith('theme-')));

    // Add theme class of currently active panel
    target.classList.add(themeClass);
  }

  smoothScrollToLastSection() {
    if (this.hasLastTarget) {
      window.scrollTo({
        top: this.lastTarget.offsetTop,
        behavior: 'smooth',
      });
      this.lastTarget.classList.add('animate__animated', 'animate__fadeInUp', 'animate__delay-3s');
    }
  }
}
